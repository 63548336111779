import request from "@/utils/request";

// StockInOrder
export function stockInOrderList(params) {
  return request({ url: `/stock_in_orders/`, method: "get", params });
}

export function stockInOrderCreate(data) {
  return request({ url: `/stock_in_orders/`, method: "post", data });
}

export function stockInOrderRetrieve(params) {
  return request({ url: `/stock_in_orders/${params.id}/`, method: "get", params });
}

export function stockInOrderMaterials(params) {
  return request({ url: `/stock_in_orders/${params.id}/stock_in_materials/`, method: "get", params });
}

export function receiptOrderPrint(data) {
  return request({ url: `/stock_in_orders/print_data/`, method: "post", data });
}

export function receiptOrderTemplate(params) {
  return request({
    url: '/stock_in_orders/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}


export function receiptOrderImport(data) {
  return request({
    url: '/stock_in_orders/import_data/',
    method: 'post',
    data,
  })
}

export function receiptOrdersExport(data) {
  return request({
    url: '/stock_in_orders/export/',
    responseType: 'blob',
    method: 'post',
    data,
  })
}


// StockOutOrder
export function stockOutOrderList(params) {
  return request({ url: `/stock_out_orders/`, method: "get", params });
}

export function stockOutOrderCreate(data) {
  return request({ url: `/stock_out_orders/`, method: "post", data });
}

export function stockOutOrderRetrieve(params) {
  return request({ url: `/stock_out_orders/${params.id}/`, method: "get", params });
}

export function stockOutOrderMaterials(params) {
  return request({ url: `/stock_out_orders/${params.id}/stock_out_materials/`, method: "get", params });
}

export function deliveryOrderPrint(data) {
  return request({ url: `/stock_out_orders/print_data/`, method: "post", data });
}

export function deliveryOrderTemplate(params) {
  return request({
    url: '/stock_out_orders/import_template/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function deliveryOrderImport(data) {
  return request({
    url: '/stock_out_orders/import_data/',
    method: 'post',
    data,
  })
}

export function deliveryOrdersExport(data) {
  return request({
    url: '/stock_out_orders/export/',
    responseType: 'blob',
    method: 'post',
    data,
  })
}
